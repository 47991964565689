.quetionTableHead {
  display: contents !important;
}

.table-container {
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #f2f2f2;
}

.table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tbody tr:hover {
  background-color: #ddd;
}

.button-container {
  margin-bottom: 10px;
}

.add-row-button {
  background-color: black;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.add-row-button:hover {
  background-color: #45a049;
}

.question-page-container {
  padding: 20px;
}
.table-container {
  margin-bottom: 20px;
}

.custom-table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.parent-column {
  text-align: center;
  color: blue !important; /* Change the color as desired */
}

.sub-column {
  color: green !important; /* Change the color as desired */
}

/* Responsive adjustments for small screens */
@media only screen and (max-width: 768px) {
  .question-page-container {
    padding: 15px !important;
  }
}

/* Responsive adjustments for extra-small screens */
@media only screen and (max-width: 480px) {
  .question-page-container {
    padding: 10px !important    ;
  }
}
